import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { HukButton, HukInputField } from "huk-react-bindings";

import { callGetSearchResultsAPI } from "shared/helper";
import {
  trackCustomEvent,
  trackCustomEventError,
} from "tealium/trackCustomEvent";
import {
  requiredFieldErrorMessage,
  inputFieldClickdAnalytics,
  pageTag,
} from "shared/messages";
import { updateRiskStateValidation } from "redux/actions/riskStateActions";

import "./WCCodeSearch.scss";

const WCCodeSearch = ({ activeTabID }) => {
  const dispatch = useDispatch();
  const [wcCode, setWcCode] = useState("");
  const [refreshCount, setRefreshCount] = useState(0);
  const userType = sessionStorage.getItem("userType");
  const { riskState } = useSelector((state) => state.riskStateReducer);
  const [showValidationError, setShowValidationError] = useState(false);
  const validationMinLength =
    riskState === "PA" || riskState === "DE" ? "3" : "4";
  const validationMessage =
    riskState === "PA" || riskState === "DE"
      ? "Enter 3 or 4 digits"
      : "Enter 4 digits";

  // refresh dynamic input field when tab or risk state is changed.  This helps remove any existing validation errors when coming back to tab or changing risk state
  /* istanbul ignore next */
  useEffect(() => {
    setRefreshCount((refreshCount % 100) + 1); // used to reset validation by using HUK refresh attribute
    setShowValidationError(false);
  }, [activeTabID, riskState]);

  /* istanbul ignore next */
  useEffect(() => {
    setWcCode("");
  }, [activeTabID]);

  /**
   * Determines if all the fields are valid to make the API call
   * @return {boolean} Determines whether all fields are valid or not
   */
  const checkAllFieldsValid = () => {
    let allFieldsValid = true;

    if (!riskState) {
      trackCustomEventError(
        "Risk State",
        `${requiredFieldErrorMessage}`,
        "true"
      );
      dispatch(updateRiskStateValidation(true));
      allFieldsValid = false;
    }

    if (!handleWCInputValidation()) {
      allFieldsValid = false;
    }
    return allFieldsValid;
  };

  const handleSubmit = (e) => {
    /* istanbul ignore next */
    e.preventDefault();

    if (!checkAllFieldsValid()) {
      return;
    }
    sessionStorage.removeItem("descriptorCode");
    const getSearchResultsPayload = {
      lobcd: "WC",
      segmentCd: "SC",
      searchId: wcCode,
      stateCd: riskState,
      returnAssocClass: "Y",
      sentinelIndicator: "Y",
      searchType: "CLASS_CODE_SEARCH_EQUAL",
      agentViewIndicator: userType === "internal" ? "N" : "Y",
    };

    callGetSearchResultsAPI(dispatch, getSearchResultsPayload);

    trackCustomEvent(
      `${pageTag}`,
      "Search Button Click",
      `WC Code Search`,
      `RiskState_${riskState} | Workers Comp Code_${wcCode}`,
      "true"
    );
  };

  /**
   * Checks to see if validation error should be shown for WC Code Input along with sending analytics
   * @return {boolean} Determines whether WC Code Input is valid or not.  This is used for the API call in checkAllFieldsValid function
   */
  const handleWCInputValidation = () => {
    if (!wcCode || (wcCode && wcCode.length < validationMinLength)) {
      trackCustomEventError(
        `Workers' Comp Code`,
        `${validationMessage}`,
        "true"
      );
      setShowValidationError(true);
      return false;
    } else {
      trackCustomEvent(
        `${pageTag}`,
        `${inputFieldClickdAnalytics}`,
        `Workers' Comp code`,
        `${wcCode}`,
        "true"
      );
      setShowValidationError(false);
      return true;
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleSubmit(e);
          }
        }}
        data-testid="WCsearchId"
      >
        <div className="wc_class_search_container">
          <div className="wcText row mb-2">
            <p className="col">
              This search will return all descriptions for the WC code you
              entered.
            </p>
          </div>
          <div className="input_row  row d-flex align-items-center">
            <div className="wc_search_input col-sm col-md-5 col-lg-4 col-xl-3 mb-3 mb-sm-0">
              <HukInputField
                nopadding
                maxlength="4"
                value={wcCode}
                nooptionaltext
                gbxunmask={true}
                aligntext="left"
                requiredasterisk
                labelformat="text"
                nohelpertextonerror
                refresh={refreshCount}
                helpertextformat="text"
                label="Workers' Comp code"
                dataerror={validationMessage}
                datamissing={validationMessage}
                data-testid="WCSearchCodeInputID"
                onHukBlur={handleWCInputValidation}
                showdataerror={showValidationError}
                required={activeTabID === "wcTabID"}
                hukclass="number-positive-only-no-decimal"
                onInput={(e) => setWcCode(e.target.value)}
                helpertext={
                  riskState === "PA" || riskState === "DE"
                    ? "Enter 3 or 4-digit NCCI code"
                    : "Enter 4-digit NCCI code"
                }
              />
            </div>
            <div className="wc_search_button col-sm col-md-5 col-lg-4 col-xl-3">
              <HukButton
                text="Search"
                type="submit"
                data-testid="WCSearchButtonID"
                data-dl={`{"da_track": "true", "event_id": "WC Code Search Btn"}`}
                // dynamically hides or shows loading spinner on button depending if all inputs are valid or not
                hukclass={"btn-primary btn-md btn-block"}
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default WCCodeSearch;
