export const searchStart = () => {
  return {
    type: "SEARCH_START",
  };
};

export const searchSuccess = (data) => {
  return {
    type: "SEARCH_SUCCESS",
    payload: data,
  };
};

export const searchFailure = (error) => {
  return {
    type: "SEARCH_FAILURE",
    payload: error,
  };
};

export const searchReset = () => {
  return {
    type: "SEARCH_RESET",
  };
};
