import React from "react";
import { useSelector } from "react-redux";

import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import {
  noResultsFound,
  somethingWentWrong,
  noBusinessMatchFound,
  noClassInformationFound,
  noResultsFoundDescription,
  somethingWentWrongDescription,
  noClassInformationFoundDescription,
} from "shared/messages";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";

const SearchResultsErrorWrapper = (props) => {
  const { data, loading, error } = useSelector(
    (state) => state.searchResultsReducer
  );
  const { confidenceScoreThresholdReached } = useSelector(
    (state) => state.businessSearchReducer
  );
  const { selectedOption } = useSelector((state) => state.searchByReducer);

  // first loading without results table
  if (loading === null) return <></>;

  if (loading) return <LoadingIndicator text={"Searching..."} />;
  if (error) {
    return (
      <ErrorMessage
        error={somethingWentWrong}
        icon="icon icon-alert-warning"
        message={somethingWentWrongDescription}
      />
    );
  }

  if (data.length === 0)
    return (
      <>
        {props.activeTabID === "spectrumTabID" &&
        selectedOption === "Business search" ? (
          <ErrorMessage
            icon="icon icon-info"
            error={
              confidenceScoreThresholdReached
                ? noBusinessMatchFound
                : noClassInformationFound
            }
            message={
              confidenceScoreThresholdReached
                ? noResultsFoundDescription
                : noClassInformationFoundDescription
            }
          />
        ) : (
          <ErrorMessage
            icon="icon icon-info"
            error={noResultsFound}
            message={noResultsFoundDescription}
          />
        )}
      </>
    );

  return props.children;
};

export default SearchResultsErrorWrapper;
