// Initial state
const initialState = {
  dynamicValue: "",
  producerCode: "",
  cpid: "",
  showValidation: false,
};

const inputValueReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_DYNAMIC_VALUE":
      return {
        ...state,
        dynamicValue: action.payload,
      };
    case "UPDATE_PRODUCER_CODE":
      return {
        ...state,
        producerCode: action.payload,
      };
    case "UPDATE_DYNAMIC_VALIDATION":
      return {
        ...state,
        showValidation: action.payload,
      };
    case "UPDATE_CPID":
      return {
        ...state,
        cpid: action.payload,
      };
    default:
      return state;
  }
};

export default inputValueReducer;
