import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { updateUDOObject } from "hig-tealium-core";

export const useUpdatedSearchResultsJSON = (searchResultsJSON, tableType) => {
  // used to get HUK table JSON and then manipulate the description column width
  const [updatedSearchResultsJSON, setUpdatedSearchResultsJSON] = useState({
    ...searchResultsJSON,
  });

  /**
   * Dynamically sets the HUK table description column width in rems
   * @return {string} width of the description column in rems
   */
  /* istanbul ignore next */
  const getNewDescColWidth = () => {
    const viewportWidth = window.innerWidth;
    // at 992 we switch over and utilize the custom mobile desktop view for spectrum results table
    if (tableType === "Spectrum") {
      if (viewportWidth < 1200) {
        return "15rem";
      } else if (viewportWidth < 1400) {
        return "25rem";
      } else {
        return "30rem";
      }
      // below is for the Mandatory forms & WC Code Search tab tables since they are the same HUK table
    } else {
      if (viewportWidth < 1200) {
        return "20rem";
      } else if (viewportWidth < 1400) {
        return "40rem";
      } else {
        return "50rem";
      }
    }
  };

  /**
   * sets and updates the new JSON file for the HUK table with the updated description column width
   * @return {void} set state for updatedSearchResultsJSON
   */
  const updateHukJSON = () => {
    // Update the width property of the fifth element of the tableHeadings array
    const newTableHeadings = [...updatedSearchResultsJSON.tableHeadings];
    const descriptionColumnIndex = newTableHeadings.findIndex(
      (item) => item["heading"] === "Description" || item["heading"] === "Title"
    );
    if (descriptionColumnIndex === -1) return;

    newTableHeadings[descriptionColumnIndex] = {
      ...newTableHeadings[descriptionColumnIndex],
      width: getNewDescColWidth(),
    };

    setUpdatedSearchResultsJSON({
      ...updatedSearchResultsJSON,
      tableHeadings: newTableHeadings,
    });
  };

  // boolean to help with throttle function below
  let inResizeThrottle;

  /**
   * Throttle function to dynamically update description column width on window resize
   * @param {string} delay amount of time to wait before calling the function to update
   * @return {void} call the function that sets the description column width
   */
  /* istanbul ignore next */
  const tableColResizeThrottleFunc = (delay) => {
    if (!inResizeThrottle) {
      inResizeThrottle = true;
      setTimeout(function () {
        updateHukJSON();

        inResizeThrottle = false;
      }, delay);
    }
  };

  // used to set the column width for the description column in Huk table either on page load or window resize
  /* istanbul ignore next */
  useEffect(() => {
    tableColResizeThrottleFunc(0);

    window.addEventListener("resize", () => tableColResizeThrottleFunc(100));

    return () => {
      window.removeEventListener("resize", () =>
        tableColResizeThrottleFunc(100)
      );
    };
  }, []);

  const resultLength = updatedSearchResultsJSON?.tableRows?.length;

  return { updatedSearchResultsJSON, resultLength };
};

// hook used to navigate to the class details page from search page in same window/browser tab
export function useClassDetailsLink() {
  const navigate = useNavigate();

  return function (
    priorPage,
    classCode,
    lob,
    riskState,
    segmentIndicator,
    producerCode
  ) {
    updateUDOObject({
      previous_page_name: priorPage,
    });
    const url = `/classDataSearch?ClassCode=${classCode}&lob=${lob}&RiskState=${riskState}&SegmentIndicator=${segmentIndicator}&ProducerCode=${producerCode}`;
    navigate(url, { state: { from: `${priorPage}` } });
  };
}
