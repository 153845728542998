export const updateBusinessName = (payload) => {
  return {
    type: "UPDATE_BUSINESS_NAME",
    payload,
  };
};

export const updateDBA = (payload) => {
  return {
    type: "UPDATE_DBA",
    payload,
  };
};

export const updateStreet = (payload) => {
  return {
    type: "UPDATE_STREET",
    payload,
  };
};

export const updateCity = (payload) => {
  return {
    type: "UPDATE_CITY",
    payload,
  };
};

export const updateState = (payload) => {
  return {
    type: "UPDATE_STATE",
    payload,
  };
};

export const updateZip = (payload) => {
  return {
    type: "UPDATE_ZIP",
    payload,
  };
};

export const updateConfidenceScoreThresholdReached = (payload) => {
  return {
    type: "UPDATE_CONFIDENCE_SCORE_THRESHOLD_REACHED",
    payload,
  };
};
