import { matchSorter } from "match-sorter";
import { getAppEnv, getGlobalEnv } from "env/env";
import { axiosPostInstance } from "shared/axiosInstance";
import {
  searchStart,
  searchFailure,
  searchSuccess,
} from "redux/actions/searchResultsActions";

// function which takes number and converts it into currency format
/**
 * Gets a hyperlink for UW Questions
 * @param {string} number  number value from response to be formatted
 * @return {string} number formatted as currency or --
 */
export const formatNumber = (number) => {
  // convert into a number as it comes in as a string from response
  number = Number(number);

  if (
    !isNaN(number) &&
    number !== 0 &&
    number !== null &&
    number !== undefined
  ) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }).format(number);
  } else {
    return "--";
  }
};

/**
 * Gets a hyperlink for UW Questions
 * @param {string} lob short code
 * @param {string} userType internal or external user
 * @param {string} classCode class code
 * @param {string} descriptorText descriptor text
 * @param {string} stateCd state code text
 * @param {string} descriptorCd descriptor code text
 * @param {string} segmentCd segment code text
 * @return {string} hyperlink
 */
export const getUWQuestionsLink = (
  lob,
  userType,
  stateCd,
  segmentCd,
  classCode,
  descriptorCd,
  descriptorText
) =>
  `${
    userType === "external"
      ? getAppEnv().EBC_DOMAIN
      : getGlobalEnv().internalDomain
  }${
    getGlobalEnv().UWQuestionsURL
  }?lobAbbr=${lob}&riskState=${stateCd}&segment=${segmentCd}&classCd=${classCode}&classDesc=${descriptorText}&classDescCd=${descriptorCd}&specAuth=1`;

export const getSearchResultsURL = `${
  getAppEnv().BASIC_API_URL
}/getsearchresults`;

export const getReportDataURL = `${getAppEnv().BASIC_API_URL}/getreportdata`;

export const getAddressCleansedURL = `${
  getAppEnv().BASIC_API_URL
}/getAddressCleansed`;

export const getSmartClassURL = `${getAppEnv().BASIC_API_URL}/getSmartClass`;

export const getEnhancedAppetiteURL = `${getAppEnv().BASIC_API_URL}/appetite`;

export const getDefaultProducerCodeURL = `${
  getAppEnv().BASIC_API_URL
}/getDefaultProducerCd`;

export const getCPIDURL = `${getAppEnv().BASIC_API_URL}/cpid`;

/**
 * Calls the getSearchResultsAPI
 * @param {hook} dispatch dispatch used to set state, success and failure in redux
 * @param {object} getSearchResultsPayload payload to be sent in the request for the API call
 */
export const callGetSearchResultsAPI = (dispatch, getSearchResultsPayload) => {
  dispatch(searchStart());
  // remove 'search' text from button so spinner can be displayed in it's place

  const sort = (results, value, lob) => {
    const items = results.filter((result) => result.lobcd === lob);

    return matchSorter(items, value, {
      keys: ["descriptorText"],
      threshold: matchSorter.rankings.NO_MATCH,
    });
  };

  const getSortedData = (searchResultsData, searchId) => {
    const spSorted = sort(searchResultsData, searchId, "SP");
    const glSorted = sort(searchResultsData, searchId, "ISO_GL");
    const wcSorted = sort(searchResultsData, searchId, "WC");
    return [...spSorted, ...glSorted, ...wcSorted];
  };

  axiosPostInstance()
    .post(getSearchResultsURL, getSearchResultsPayload)
    .then((res) => {
      const data = res.data;
      const sortedData = getSortedData(
        data.searchResultsLineItemList,
        getSearchResultsPayload.searchId
      );

      dispatch(searchSuccess(sortedData));
    })
    .catch((error) => {
      dispatch(searchFailure(error));
    });
};

export const getProducerContactsByUserIdURL = `${
  getAppEnv().BASIC_API_URL
}/getProducerContactsByUserId`;

/*
  css styles for appetite icons
  need in this format bc search results table is HUK table in JSON format and only accept 'style' and not 'class' in order to color icons correctly
*/
const green = "#118654";
const blue = "#3a5a78";
const red = "#b83b2f";

// T and A Appetite codes are the same so created universal object that is referenced in 2 places
const inAppetite = {
  text: "In Appetite",
  icon: "bullet-check-mark",
  color: green,
};

// Mapping for any data related to appetite code
const allTALDAppetiteCodeMap = {
  T: inAppetite,
  A: inAppetite,
  L: {
    text: "Limited Appetite",
    icon: "attention-alt",
    color: blue,
  },
  N: {
    text: "No Appetite",
    icon: "bullet-not-valid",
    color: red,
  },
  NTH: { text: "--", icon: "" },
};

/**
 * Sets the appetite text and icon
 * @param {string} appetiteCode from response
 * @param {string} type "icon" or "text"
 * @return {object} containing the HukIcon and text
 */
export const allTALDAppetiteCode = (appetiteCode, type) => {
  if (!["T", "L", "N", "A"].includes(appetiteCode)) appetiteCode = "NTH";

  return allTALDAppetiteCodeMap[appetiteCode][type];
};
