// Initial state
const initialState = {
  name: "Description",
  selectedOption: "Description",
  searchType: "SUPER_DESCRIPTION_SEARCH_CONTAINS",
  dynamicField: {
    range: [3, 60],
    validationMessage: "Enter at least 3 characters",
    helperText: "Enter at least 3 characters",
  },
};

// Reducer function
const searchByReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHANGE_SEARCHBY":
      const data = action.payload;
      return {
        name: data?.name,
        selectedOption: data?.name,
        searchType: data?.searchType,
        dynamicField: { ...data?.dynamicField },
      };
    default:
      return state;
  }
};

export default searchByReducer;
