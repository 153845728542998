import { BrowserCacheLocation } from "@azure/msal-browser";

import { getAppEnv } from "env/env";

// MSAL configuration
export const internalConfiguration = {
  auth: {
    clientId: getAppEnv().INTERNAL_AUTH.CLIENT_ID,
    authority: getAppEnv().INTERNAL_AUTH.AUTHORITY,
    redirectUri: "/",
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage,
  },
};

export const loginRequest = {
  scopes: [`api://${getAppEnv().INTERNAL_AUTH.CLIENT_ID}/Cafe.Read`],
};

export const REFRESH_THRESHOLD = 300; // 5 minutes in seconds
