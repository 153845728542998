import { BrowserCacheLocation } from "@azure/msal-browser";

import { getAppEnv } from "env/env";

const getExternalEnv = () => getAppEnv().EXTERNAL_AUTH;

// MSAL configuration
export const externalConfiguration = {
  auth: {
    clientId: getExternalEnv().CLIENT_ID,
    authority: getExternalEnv().AUTHORITY,
    redirectUri: getExternalEnv().REDIRECT_URI,
    knownAuthorities: getExternalEnv().KNOWAUTHORITIES,
    OIDCOptions: { serverResponseType: "query", defaultScopes: ["openid"] },
    protocolMode: "OIDC",
    authorityMetadata: JSON.stringify({
      scopes_supported: getExternalEnv().SCOPES,
      token_endpoint: `${getExternalEnv().AUTHORITY}/token`,
      authorization_endpoint: `${getExternalEnv().AUTHORITY}/authorize`,
      end_session_endpoint: `${getExternalEnv().AUTHORITY}/revoke`,
      issuer: "https://www.thehartford.com",
      jwks_uri: "https://www.thehartford.com",
      token_endpoint_auth_methods_supported: ["client_secret_post"],
      response_modes_supported: ["fragment", "query", "form_post"],
      subject_types_supported: ["public"],
      id_token_signing_alg_values_supported: ["HS256", "RS256"],
      response_types_supported: ["code", "code id_token"],
      request_uri_parameter_supported: false,
      claims_supported: [
        "jti",
        "sub",
        "iss",
        "aud",
        "exp",
        "iat",
        "at_hash",
        "nonce",
      ],
    }),
  },

  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage,
    storeAuthStateInCookie: false,
  },
};

// Generate a PKCE verifier
const generateRandomString = (length) => {
  // Generate a random string of the specified length
  const charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let randomString = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    randomString += charset[randomIndex];
  }

  return randomString;
};

// Generate the code challenge using the verifier
const generateCodeChallenge = async (codeVerifier) => {
  // Generate the code challenge from the code verifier
  const encoder = new TextEncoder();
  const data = encoder.encode(codeVerifier);
  const hashBuffer = await crypto.subtle.digest("SHA-256", data);
  const codeChallenge = base64UrlEncode(hashBuffer);

  return codeChallenge;
};

// Helper function to base64 URL encode
const base64UrlEncode = (buffer) => {
  const encoded = btoa(String.fromCharCode.apply(null, new Uint8Array(buffer)))
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");

  return encoded;
};

// Generate PKCE code verifier
const codeVerifier = generateRandomString(64);

// Generate PKCE code challenge
const codeChallenge = await generateCodeChallenge(codeVerifier);

// Specify authentication parameters with PKCE challenge
export const loginRequest = {
  scopes: getExternalEnv().SCOPES,
  codeChallenge: codeChallenge,
  codeChallengeMethod: "S256",
};

export const REFRESH_THRESHOLD = 60; // 1 minutes in seconds
