import React from "react";

/**
 * Gets the object listing available WC codes along with other details specific for the class code
 * @param {object} reportData object containing report data for the class code
 * @return {string} object listing WC codes along with other details
 */
export const getWCCodes = (reportData) => {
  return (reportData?.commonWCCodes ?? []).reduce((hashMap, item) => {
    hashMap[item.classCode] = hashMap[item.classCode] ?? [];
    hashMap[item.classCode].push(item);

    return hashMap;
  }, {});
};

/**
 * Sets the description text for the class code popover
 * @param {string} item class code number
 * @param {object} wcCodes object listing WC codes along with other details
 * @return {string}  class code description text
 */
export const setClassCodeDescriptionText = (item, wcCodes) => {
  if (wcCodes[item].length > 1) {
    return wcCodes[item].map((item, index) => (
      <React.Fragment key={index}>
        <div data-testid="wcDescriptionID">
          <strong>{item.classCode}</strong> - {item.descriptorText}
        </div>
      </React.Fragment>
    ));
  } else {
    return wcCodes[item].map((item) => item.descriptorText);
  }
};
