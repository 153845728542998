export const uwIndexDescArray = [
  {
    uwIndex: "L",
    title: "L - Low:",
    description:
      "Light hazard; risks present very limited severity potential; includes classes with high degree of homogeneity with respect to exposures; can be handled with minimal underwriting; typically can be written based on risk information obtained from production source and complemented with external data sources; generally corresponds to Best HC 1-3.",
  },
  {
    uwIndex: "M",
    title: "M - Moderate:",
    description:
      "Moderate hazard; risks present limited frequency or severity potential; smaller risks can be handled with limited underwriting, while larger risks require more analysis; moderate variability in exposures among all risks in class; smaller risks in class can be written based on risk information obtained from production source and complemented with external data sources, while larger risks require supplemental underwriting information; generally corresponds to Best HC 4-6.",
  },
  {
    uwIndex: "H",
    title: "H - High:",
    description:
      "High hazard; risks present potential for frequency of loss or high severity; must be underwritten judiciously, using rigid risk selection practices; high variability in exposures from risk to risk; typically require supplemental information such as Loss Control, even for small risks; generally corresponds to Best HC 7-8.",
  },
  {
    uwIndex: "X",
    title: "X - Severe:",
    description:
      "Very high hazard; risks present the highest severity potential; typically not eligible for Select Customer; only the very best risks in class will present profit potential; require detailed underwriting information and analysis; generally corresponds to Best HC 9-10.",
  },
];
