import { addEvent } from "hig-tealium-core";

export const trackCustomEvent = (
  event_parent,
  event_type,
  event_id,
  event_value,
  daTrack
) => {
  addEvent(
    {
      event_parent: event_parent,
      event_type: event_type,
      event_id: event_id,
      event_value: event_value,
    },
    daTrack
  );

  return null;
};

export const trackCustomEventError = (event_id, event_value, daTrack) => {
  addEvent(
    {
      event_parent: "Form Field Validation Error",
      event_type: "Form Error Message",
      event_id: event_id,
      event_value: event_value,
    },
    daTrack
  );

  return null;
};
