// show to both internal and external
export const agencyInstructionList = [
  "DS",
  "RE",
  "SU",
  "UN",
  "WO",
  "NM",
  "NF",
  "PR",
  "GL",
  "SA",
  "WA",
  "CB",
  "HI",
  "HF",
  "DC",
  "AO",
  "UP",
  "PL",
  "WB",
  "LA",
  "SL",
  "CN",
];
