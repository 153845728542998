import React from "react";

import { getAppEnv } from "env/env";

import "./Footer.scss";

const Footer = () => {
  const today = new Date();
  const year = today.getFullYear();

  return (
    <div className="footer_container" data-testid="FooterID">
      <div className="footer_content">
        <hr className="footer_line" />
        <div className="row d-flex">
          <div className="col-md-4">&copy; {year} The Hartford</div>
          <div className="col-md-8 mt-2 mt-md-0">
            <ul className="float-none float-md-end">
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${getAppEnv()?.EBC_DOMAIN}/terms-of-use`}
                >
                  Terms of Use
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={"https://www.thehartford.com/accessibility-statement"}
                >
                  Accessibility Statement
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={"https://www.thehartford.com/legal-notice"}
                >
                  Legal Notice
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={"https://www.thehartford.com/privacy-stand-alone"}
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="californiaPrivacyLink"
                  href={"https://www.thehartford.com/data-privacy-opt-out-form"}
                >
                  Your California Privacy Choices
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
