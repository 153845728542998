import { useEffect, useRef } from "react";
import {
  InteractionType,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import {
  useMsal,
  useMsalAuthentication,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";

import { loginRequest, REFRESH_THRESHOLD } from "./externalAuthConfig";

const setRefreshTime = () => {
  const accessToken = sessionStorage.getItem("accessToken");
  const expiresOn = sessionStorage.getItem("expiresOn");

  if (accessToken && expiresOn) {
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    const expTime = Math.floor(new Date(expiresOn).getTime() / 1000);
    const timeUntilExpiry = expTime - currentTime - REFRESH_THRESHOLD;

    return timeUntilExpiry;
  }

  return null;
};

const ExternalAuthProvider = ({ children }) => {
  const interval = useRef(null);
  const { instance, accounts } = useMsal();

  const refreshTime = setRefreshTime();

  if (refreshTime !== null && refreshTime <= 0) {
    sessionStorage.clear();
  }

  const { login, result, error } = useMsalAuthentication(
    InteractionType.Silent,
    loginRequest
  );

  if (error) {
    console.log(error);
  }

  if (result) {
    sessionStorage.setItem("accessToken", result.accessToken);
    sessionStorage.setItem("expiresOn", result.expiresOn);
    sessionStorage.setItem("ebcUID", accounts[0]?.homeAccountId);
  }

  useEffect(() => {
    if (error instanceof InteractionRequiredAuthError) {
      login(InteractionType.Redirect, loginRequest);
    }
  }, [error]);

  const acquireTokenWithRefreshToken = async () => {
    try {
      if (accounts.length > 0 && instance) {
        const response = await instance.acquireTokenSilent({
          account: accounts[0],
          ...loginRequest,
        });

        sessionStorage.setItem("accessToken", response.accessToken);
        sessionStorage.setItem("expiresOn", response.expiresOn);
        sessionStorage.setItem("ebcUID", accounts[0]?.homeAccountId);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    setInterval.current = setInterval(() => {
      const refreshTime = setRefreshTime();

      if (refreshTime !== null && refreshTime <= 0) {
        acquireTokenWithRefreshToken();
      }
    }, 30000); // every 30 seconds call set internval

    return () => clearInterval(interval.current);
  }, [accounts]);

  return (
    <>
      <UnauthenticatedTemplate data-testid="unauthenticated">
        <LoadingIndicator text="Loading..." fullScreen />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate data-testid="authenticated">
        {children}
      </AuthenticatedTemplate>
    </>
  );
};

export default ExternalAuthProvider;
