import axios from "axios";

import { getAppEnv } from "env/env";

const getAccessToken = () => sessionStorage.getItem("accessToken");

export const axiosGetInstance = async () => {
  const accessToken = await getAccessToken();
  const instance = axios.create({
    headers: {
      Authorization: accessToken ? `Bearer ${accessToken}` : "",
    },
    withCredentials: false,
  });

  return instance;
};

export const axiosPostInstance = () => {
  const accessToken = getAccessToken();
  const instance = axios.create({
    headers: {
      Authorization: accessToken ? `Bearer ${accessToken}` : "",
      "Content-Type": "application/json",
      Accept: "application/json",
      "Client-Id": getAppEnv().IBM_CLIENT_ID,
    },
    withCredentials: false,
  });

  return instance;
};
