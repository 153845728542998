import React from "react";
import { HukIcon } from "huk-react-bindings";

import "./LoadingIndicator.scss";

const LoadingIndicator = ({ text, fullScreen = false }) => (
  <div data-testid="loadingIndicatorID" className={fullScreen ? "vh-100" : ""}>
    <div className="loading_indicator_container" data-testid="spinnerId">
      <center>
        <div>
          <HukIcon
            iconsize="5x"
            icon="icon icon-spinner icon-pulse"
            className="loading_indicator_font_color"
          />
        </div>
        <div className="search_text_style">{text}</div>
      </center>
    </div>
  </div>
);

export default LoadingIndicator;
