import { HukIcon, HukPopover } from "huk-react-bindings";

import { pageTag } from "shared/messages";
import { getAppEnv, getGlobalEnv } from "env/env";

import { agencyInstructionList } from "./data";

import "./AgencyInstruction.scss";

/**
 * Sets icon type to be used with the HukPopover
 * @param {string} agencyInstructionCode instruction code from response
 * @return {string} correct huk icon class name
 */
const getIcon = (agencyInstructionCode) => {
  if (agencyInstructionCode === "DS") {
    return "icon icon-stop";
  } else if (agencyInstructionCode === "CB") {
    return "icon icon-unavailable-error-unable";
  } else {
    return "icon icon-info";
  }
};

const AgencyInstruction = ({ reportData, lob, userType }) => {
  /**
   * Sets the agency instruction text based on if it's a link to a PDF or plain text
   * @param {object} agencyInstruction - Agency instruction object from response
   * @return {JSX} content containing agency instruction text either as a link to PDF or plain text
   */
  const createInstructionText = (agencyInstruction) => {
    if (agencyInstruction.commentText === null) {
      const linkVLContent = agencyInstruction.linkVL.split(",");
      const responsePDFValue = linkVLContent[2];
      const pdfLink = createPDFLink(responsePDFValue);

      return (
        <a
          href={pdfLink}
          target="_blank"
          rel="noopener noreferrer"
          className="agency_instructions"
          data-dl={`{"event_parent": "${pageTag}", "event_type": "Link Click", "event_id": "${agencyInstruction.agentInstructionText}", "event_value": "${lob.text}", "da_track": "true"}`}
        >
          {agencyInstruction.agentInstructionText}
        </a>
      );
    } else {
      return agencyInstruction.agentInstructionText;
    }
  };

  /**
   * Creates a hyperlink for agency instruction PDF
   * @param {string} pdfLink PDF section from response (i.e. Professional_Liability_Coverage.pdf)
   * @return {string} hyperlink
   */
  const createPDFLink = (pdfLink) =>
    `${
      userType === "external"
        ? getAppEnv().EBC_DOMAIN
        : getGlobalEnv().internalDomain
    }/contentDelivery/selfPublish/cdr/${pdfLink}`;

  /**
   * Creates the JSX content that contains both agency instruction text and icon
   * @param {string} lob code from response (i.e. "CA" or "SPEC")
   * @return {JSX} JSX content containing icon or popover and agency instruction text
   */
  const createAgencyInstructionJSX = (lob) => {
    const agentInstructionObjects = reportData?.agencyInstructions
      .filter((item) => item.lobcode === lob)
      .map(
        (item, index) =>
          setShowOrHideInstruction(item.agentInstructionCode) && (
            <div key={index} className="col-md d-flex align-items-center">
              <div className="me-2 pt-2">
                {/* if the commentText is null use a Huk Icon otherwise use a Huk Popover to display commentText */}
                {item.commentText === null ? (
                  <HukIcon icon="info" />
                ) : (
                  <HukPopover
                    alignment="left"
                    type="tooltip-icon"
                    popoverplacement="top"
                    data-testid="Huk_Popover_Id"
                    className="huk_popover_size"
                    icon={getIcon(item.agentInstructionCode)}
                    iconcolor={
                      item.agentInstructionCode === "DS" ||
                      item.agentInstructionCode === "CB"
                        ? "red"
                        : "blue"
                    }
                  >
                    {item.commentText}
                  </HukPopover>
                )}
              </div>
              <div className="agency_instructions">
                {createInstructionText(item)}
              </div>
            </div>
          )
      );

    return agentInstructionObjects;
  };

  /**
   * Determines if instruction should be shown or hidden based on instruction code and user type
   * @param {string} instructionCode - Agency instruction code from response (i.e. "CN" or "DS" or "RE")
   * @return {boolean} True/False
   */
  const setShowOrHideInstruction = (instructionCode) => {
    if (agencyInstructionList.includes(instructionCode)) {
      if (userType === "external" && instructionCode === "CN") {
        return false; // Do NOT show to external users
      } else {
        return true; // SHOW to internal users and external users
      }
    } else {
      return false;
    }
  };

  return (
    <div
      data-testid="Agency_Instruction_Id"
      className={"agency_instructions_container align-items-center col-md-3"}
    >
      {createAgencyInstructionJSX(lob.shortCode)}
    </div>
  );
};

export default AgencyInstruction;
