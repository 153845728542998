import { HukModal } from "huk-react-bindings";
import { useRef, Fragment, useState } from "react";

import { pageTag } from "shared/messages";
import { getUWQuestionsLink } from "shared/helper";

import Hazard from "./Hazard/Hazard";
import UWIndex from "./UWIndex/UWIndex";
import Appetite from "./Appetite/Appetite";
import { isoLOB, spectrumLOB } from "./data";
import UWQuestions from "./UWQuestions/UWQuestions";
import AgencyInstruction from "./AgencyInstruction/AgencyInstruction";

import { agencyInstructionList } from "./AgencyInstruction/data";

import "./AppetiteGuide.scss";

const AppetiteGuide = ({ searchData, reportData, segmentCd }) => {
  const modalRef = useRef(null);
  const [userType] = useState(sessionStorage.getItem("userType"));

  /**
   * Creates the content for each LOB row in the table based on if it's Spectrum or ISO GL
   * @param {array} lobArray Objects containing data specific to either Spectrum or GL LOB
   * @return {JSX}
   */
  const createAppetiteRow = (lobArray) => {
    return lobArray.map((lob, index) => (
      <Fragment key={index}>
        {/* if there are 2+ agency instructions let the row height adjust dynamically, any other rows set a static row height */}
        {/* LOB title row */}
        <div
          className={`row align-items-center ${
            /* istanbul ignore next */
            reportData?.agencyInstructions.filter(
              (instruction) =>
                instruction.lobcode === lob.shortCode &&
                agencyInstructionList.some((item) => item === instruction)
            )?.length > 1
              ? ""
              : "py-1 py-md-2"
          }`}
        >
          <div className={"col-md-3 lob_type mt-3 mt-md-0"}>{lob.text}</div>
          {/* Appetite icon & text */}
          <Appetite lob={lob} searchData={searchData} userType={userType} />
          {/* UW Index & HAZ Codes */}
          {userType === "internal" && (
            <>
              {searchData?.businessClassCdTypeName === "SPCTRM" && (
                <UWIndex lob={lob} searchData={searchData} />
              )}
              <Hazard lob={lob} searchData={searchData} />
            </>
          )}
          {/* Agency instructions icon & text */}
          <AgencyInstruction
            lob={lob}
            userType={userType}
            reportData={reportData}
          />
          {/* UW questions */}
          <UWQuestions
            lob={lob}
            userType={userType}
            segmentCd={segmentCd}
            hukModalRef={modalRef}
            searchData={searchData}
            reportData={reportData}
          />
        </div>
        {index === lobArray.length - 1 ? "" : <hr />}
      </Fragment>
    ));
  };

  return (
    <div
      data-testid="AppetiteGuideId"
      className="appetite_guide_container p-0 mt-2"
    >
      <div
        className={`row appetite_table_header ${
          userType === "internal" ? "appetite_header_internal" : "mb-sm-3"
        }`}
      >
        <h2 className="mb-2 mb-md-0">Appetite</h2>
      </div>
      {userType === "internal" && (
        <div className="row align-items-end" data-testid="testID">
          {/* empty column to provide proper positioning and spacing for the Appetite/UW Index/HAZ column headers */}
          <div className="col-md-3"></div>
          <div className="col-md-2 d-none d-md-block">
            <p className="column_headers fw-medium mb-0">Appetite</p>
          </div>
          {searchData?.businessClassCdTypeName === "SPCTRM" && (
            <div className="col-md-1 d-none d-md-block">
              <p className="column_headers fw-medium mb-0">
                {/* span tags needed as wireframe wants the words to be stacked ontop of each other */}
                <span>UW</span>
                <br />
                <span>Index</span>
              </p>
            </div>
          )}
          <div className="col-md-1 d-none d-md-block">
            <p className="column_headers fw-medium mb-0">HAZ</p>
          </div>
        </div>
      )}
      <hr className="blue_border" />
      <div className="mt-md-2 px-md-2">
        {searchData?.businessClassCdTypeName === "SPCTRM"
          ? createAppetiteRow(spectrumLOB)
          : createAppetiteRow(isoLOB)}
      </div>
      {/*
        Modal needs to stay down here and not in createAppetiteRow function
        This is because that function uses a map and we only need modal for WC and not for every row
      */}
      <HukModal
        size="lg"
        closeicon
        ref={modalRef}
        verticalcenter
        hukid="UWQuestionsModal"
        headertitle="Underwriting Questions"
        footerbuttons={[{ text: "Close", hukclass: ["btn-primary", "btn-xs"] }]}
      >
        {reportData?.commonWCCodes?.map((item, index) => {
          // return is needed to allow istanbul ignore next to fire and not be included in code coverage report
          /* istanbul ignore next */
          return (
            <div key={index} className="d-flex align-items-baseline">
              <div className="me-4 fw-bold">{item.classCode}</div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={getUWQuestionsLink(
                  "WC",
                  userType,
                  item.stateCd,
                  segmentCd,
                  item.classCode,
                  item.descriptorCode,
                  item.descriptorText
                )}
                data-dl={`{"event_parent": "${pageTag}", "event_type": "Link Click", "event_id": "UW Questions", "event_value": "Workers Compensation", "da_track": "true"}`}
              >
                {item.descriptorText}
              </a>
            </div>
          );
        })}
      </HukModal>
      <hr className="blue_border" />
    </div>
  );
};

export default AppetiteGuide;
