import { HukIcon } from "huk-react-bindings";
import { allTALDAppetiteCode } from "shared/helper";

import "./Appetite.scss";

const Appetite = ({ searchData, lob, userType }) => (
  <div
    className={`appetite_container align-items-center d-flex mt-2 mb-1 my-md-0 ${
      userType === "internal" ? "col-md-2" : "col-md-3"
    } appetite_row`}
  >
    {/* only show 'Appetite' column header for internal users on mobile*/}
    {userType === "internal" && (
      <div className="d-md-none me-2 lob_type">Appetite:</div>
    )}
    <div className="me-2 pt-2">
      {searchData?.[lob.appetiteCode] !== "--" && (
        <HukIcon
          style={{
            color: allTALDAppetiteCode(searchData?.[lob.appetiteCode], "color"),
          }}
          icon={allTALDAppetiteCode(searchData?.[lob.appetiteCode], "icon")}
        />
      )}
    </div>
    <div
      className="appetite_icon_and_text"
      data-testid={`${lob.shortCode}-text`}
    >
      {allTALDAppetiteCode(searchData?.[lob.appetiteCode], "text")}
    </div>
  </div>
);

export default Appetite;
