const originalWC = {
  range: [4, 4],
  validationMessage: "Enter 4 digits",
  helperText: "Enter 4-digit NCCI code",
};

const updatedWC = {
  range: [3, 4],
  validationMessage: "Enter 3 or 4 digits",
  helperText: "Enter 3 or 4-digit NCCI code",
};

/**
 * Creates and returns the correct WC object to be dispatched based on the risk state
 * @param {string} riskState - Risk state selection from the drop down
 * @return {object} - Updated WC object to be dispatched based on state
 */
export const updateWCSearchType = (riskState) => ({
  name: "Workers' Comp code",
  value: "Workers' Comp code",
  searchType: "WC_CODE_SEARCH_EQUAL",
  checked: false,
  dynamicField:
    riskState === "DE" || riskState === "PA" ? updatedWC : originalWC,
});
