import { HukTableXc } from "huk-react-bindings";

import { useUpdatedSearchResultsJSON } from "shared/hooks";

import "./WCCodeDesktopResultsTable.scss";

const WCCodeDesktopResultsTable = ({ WCSearchResultsJson }) => {
  const { updatedSearchResultsJSON, resultLength } =
    useUpdatedSearchResultsJSON(WCSearchResultsJson, "WCCode");

  return (
    <div
      data-testid="WCCodeDesktopResultsTableID"
      className="wc_desktop_results_table_container px-3"
    >
      <h3 className="header">{resultLength} Results</h3>
      <div className="results_table">
        <HukTableXc tablejson={updatedSearchResultsJSON} />
        <hr className="d-none d-md-block" />
      </div>
    </div>
  );
};

export default WCCodeDesktopResultsTable;
