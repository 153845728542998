import { HukIcon } from "huk-react-bindings";

import "./AlternativeMarketPlan.scss";

const AlternativeMarketPlan = ({ searchData }) => {
  const userType = sessionStorage.getItem("userType");

  const { altrntMarketPlanText } = searchData || {};

  const targetWord = "For more";
  const index = altrntMarketPlanText?.lastIndexOf(targetWord);

  let alternativeMarketText = "";
  if (index !== -1) {
    alternativeMarketText = altrntMarketPlanText?.substring(0, index);
  } else {
    alternativeMarketText = altrntMarketPlanText;
  }

  return (
    <div className="row amp_container h-100">
      <div
        className={`${
          altrntMarketPlanText ? "amp_column" : "amp_column invisible"
        } ${
          userType === "external"
            ? "py-4 px-sm-5 ps-4 external_amp_color"
            : "px-4 pt-3 pb-2"
        }`}
      >
        <div
          className={`align-items-center ${
            userType === "external" ? "row" : "d-flex amp_text_internal"
          }`}
        >
          <div
            className={`pt-2 icon_style ${
              userType === "external" ? "col-1 me-1" : "me-2"
            }`}
          >
            <HukIcon
              icon="bullet-check-mark"
              className="bullet_not_valid_style"
            />
          </div>
          <div
            className={`fw-medium icon_heading ${
              userType === "external" ? "col" : ""
            }`}
          >
            Alternative Market Placement
          </div>
        </div>
        <p
          className={`lh-sm ${
            userType === "internal" ? "amp_text_internal" : ""
          }`}
        >
          {alternativeMarketText}
          For more information on when to consider AMP, please contact us at
          866-467-8730 or email{" "}
          <a
            href={`mailto:
                AMP@TheHartford.com?subject=${
                  searchData?.siccode ? "SIC" : "Class"
                } Code  -  ${
              searchData?.siccode
                ? searchData?.siccode + ` -  ${searchData?.sicdescription}`
                : searchData?.classCode
            }`}
            className="amp_email_link"
            data-dl={`{"da_track": "true"}`}
          >
            AMP@TheHartford.com
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default AlternativeMarketPlan;
