const MaxLimitRow = ({ data, description }) => (
  <>
    <div className="row">
      <div className="col-5 col-sm text-sm-end table_data blue_font_color">
        {data}
      </div>
      <div className="col">{description}</div>
    </div>
  </>
);

export default MaxLimitRow;
