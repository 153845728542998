import { useRef } from "react";
import { HukModal } from "huk-react-bindings";

import { uwIndexDescArray } from "./data";

import "./UWIndex.scss";

const UWIndex = ({ searchData, lob }) => {
  const modelRef = useRef(null);

  /**
   * Handles the clicking/launching of the HUK Modal
   */
  const handleModalOpen = () => {
    modelRef.current?.present();
  };

  /**
   * Displays the UW Index title and description inside the HUK Modal
   * @param {string} uwIndex UW Index returned from searchResults API response.
   * @return {JSX} JSX of Title and description of UW Index to display in HUK Modal
   */
  const getUWIndexDescription = (uwIndex) => {
    const uwIndexposition = uwIndexDescArray.findIndex(
      (desc) => desc.uwIndex === uwIndex
    );

    const uwIndextitle =
      uwIndexposition !== -1 ? uwIndexDescArray[uwIndexposition].title : null;

    const uwIndexdescription =
      uwIndexposition !== -1
        ? uwIndexDescArray[uwIndexposition].description
        : null;

    return (
      <>
        <span
          className={`fw-bold uw_index_letter index-${
            searchData?.[lob.uWIndexCd]
          }`}
        >
          {uwIndextitle} &nbsp;
        </span>
        {uwIndexdescription}
      </>
    );
  };

  return (
    <div
      className={`UWIndex_container d-flex col-md-1 my-md-0 ${
        searchData?.[lob.uWIndexCd] && "my-1"
      }`}
    >
      {searchData?.[lob.uWIndexCd] && (
        <div className="d-md-none me-2 lob_type">UW Index:</div>
      )}
      {/* UW Index as button to help with accessibility.  This isn't technically a link so button tag is more appropriate then a tag */}
      <button
        onClick={handleModalOpen}
        aria-label={searchData?.[lob.uWIndexCd] || "Underwiting Index letter"}
        className={`align-items-center fw-bold uw_index_letter index-${
          searchData?.[lob.uWIndexCd]
        }`}
      >
        {searchData?.[lob.uWIndexCd]}
      </button>
      <HukModal
        size="lg"
        closeicon
        ref={modelRef}
        verticalcenter
        hukid="UWIndexModal"
        headertitle="Underwriting Index Description"
        footerbuttons={[{ text: "Close", hukclass: ["btn-primary", "btn-xs"] }]}
      >
        {getUWIndexDescription(searchData?.[lob.uWIndexCd])}
      </HukModal>
    </div>
  );
};

export default UWIndex;
