import "./Hazard.scss";

const Hazard = ({ searchData, lob }) => {
  /**
   * Removes the leading 0 from the hazard code
   * @param {string} hazardCode key needed to get the hazard code value from response
   * @return {string} whole number with no leading 0 converted from a string or "--"
   */
  const convertHazardCode = (hazardCode) => {
    if (hazardCode === "N/A - UMB") {
      return "";
      // SPEC(BOP) HAZ codes should actually display the commercial property HAZ code
    } else if (hazardCode === "specHazardCd") {
      return `Prop: ${
        parseInt(searchData?.["propHazardCd"]) || searchData?.["propHazardCd"]
      }`;
    } else {
      return parseInt(searchData?.[hazardCode]) || searchData?.[hazardCode];
    }
  };

  return (
    <div
      className={`hazard_container d-flex col-md-1 my-md-0 ${
        lob.hazardCd === "specHazardCd"
          ? "p-md-0"
          : convertHazardCode(lob.hazardCd) && "my-1"
      }`}
    >
      {convertHazardCode(lob.hazardCd) && (
        <div className="d-md-none me-2 lob_type">HAZ:</div>
      )}
      <div
        // set font-weight based on if the hazard code is for Spectrum and/or if it's a number or not
        className={`align-items-center ${
          lob.hazardCd === "specHazardCd"
            ? "fw_500"
            : convertHazardCode(lob.hazardCd) !== "--"
            ? "fw-bold"
            : "fw_500"
        }`}
      >
        {convertHazardCode(lob.hazardCd)}
      </div>
    </div>
  );
};

export default Hazard;
