export const updateRiskState = (payload) => {
  return {
    type: "UPDATE_RISK_STATE",
    payload,
  };
};

export const updateRiskStateValidation = (payload) => {
  return {
    type: "UPDATE_RISK_STATE_VALIDATION",
    payload,
  };
};
