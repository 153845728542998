// Initial state
const initialState = {
  businessName: "",
  dbaName: "",
  street: "",
  city: "",
  state: "",
  zipCode: "",
  confidenceScoreThresholdReached: true,
};

const businessSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_BUSINESS_NAME":
      return {
        ...state,
        businessName: action.payload,
      };
    case "UPDATE_DBA":
      return {
        ...state,
        dbaName: action.payload,
      };
    case "UPDATE_STREET":
      return {
        ...state,
        street: action.payload,
      };
    case "UPDATE_CITY":
      return {
        ...state,
        city: action.payload,
      };
    case "UPDATE_STATE":
      return {
        ...state,
        state: action.payload,
      };
    case "UPDATE_ZIP":
      return {
        ...state,
        zipCode: action.payload,
      };
    case "UPDATE_CONFIDENCE_SCORE_THRESHOLD_REACHED":
      return {
        ...state,
        confidenceScoreThresholdReached: action.payload,
      };
    default:
      return state;
  }
};

export default businessSearchReducer;
