import React, { useRef } from "react";
import { HukModal, HukIcon } from "huk-react-bindings";

import "./AppetiteHelpModal.scss";

export const AppetiteHelpModal = ({ mobileView = false }) => {
  const modelRef = useRef(null);

  const handleModalOpen = () => {
    modelRef.current?.present();
  };

  return (
    <div
      className={[
        "appetite_help_modal_container",
        mobileView ? "" : " col-sm-2 col-md-3 col-xl-4 col-xxl-5",
      ].join("")}
    >
      <div className="d-flex justify-content-lg-end">
        <button
          className="d-flex align-items-center cursor_pointer"
          onClick={handleModalOpen}
        >
          <div className={`pt-2 ${mobileView ? "me-3" : "ms-auto me-2"}`}>
            <HukIcon
              iconsize="2x"
              icon="icon icon-questions"
              className="questions_icon"
            />
          </div>
          <div className="fw-medium appetite_help ">Appetite Help</div>
        </button>
        <HukModal
          size="lg"
          closeicon
          ref={modelRef}
          verticalcenter
          hukid="Appetitemodel"
          headertitle="Appetite Key"
          data-testid="AppetiteModalID"
          footerbuttons={[
            { text: "Close", hukclass: ["btn-primary", "btn-xs"] },
          ]}
        >
          <div className="InAppetite mb-3 pt-3">
            <div className="d-flex align-items-center">
              <div className={["pt-2", mobileView ? "me-3" : "me-2"].join(" ")}>
                <HukIcon
                  iconsize="2x"
                  icon="bullet-check-mark"
                  className="bullet_check_mark_icon"
                />
              </div>
              <div
                className={[
                  "fw-medium appetite_type_title",
                  mobileView ? "" : " ms-3",
                ].join("")}
              >
                In Appetite
              </div>
            </div>
            <div className="fw-medium ms-5">
              We highly desire to insure businesses in this class.
            </div>
          </div>
          <div className="LimitedAppetite mb-3">
            <div className="d-flex align-items-center">
              <div className={`pt-2 ${mobileView ? "me-3" : "me-2"}`}>
                <HukIcon
                  iconsize="2x"
                  className="attention_alt_icon"
                  icon="attention-alt"
                />
              </div>
              <div
                className={`fw-medium appetite_type_title ${
                  !mobileView && "ms-3"
                }`}
              >
                Limited Appetite
              </div>
            </div>
            <div className="fw-medium ms-5">
              We offer insurance to select businesses in this class.
            </div>
          </div>
          <div className="NoAppetite mb-3">
            <div className="d-flex align-items-center">
              <div className={`pt-2 ${mobileView ? "me-3" : "me-2"}`}>
                <HukIcon
                  iconsize="2x"
                  icon="bullet-not-valid"
                  className="bullet_not_valid_icon "
                />
              </div>
              <div
                className={`fw-medium appetite_type_title ${
                  !mobileView && "ms-3"
                }`}
              >
                No Appetite
              </div>
            </div>
            <div className="fw-medium ms-5">
              We don't insure businesses in this class.
            </div>
          </div>
        </HukModal>
      </div>
    </div>
  );
};
