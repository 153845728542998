import { HukInputField } from "huk-react-bindings";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { inputFieldClickdAnalytics, pageTag } from "shared/messages";
import { changeSearchBy } from "redux/actions/searchByActions";
import {
  trackCustomEventError,
  trackCustomEvent,
} from "tealium/trackCustomEvent";
import {
  updateDynamicValue,
  updateDynamicValidation,
} from "redux/actions/inputValueActions";

import { updateWCSearchType } from "../../helper";

const NumberInput = ({
  helperText,
  activeTabID,
  refreshCount,
  selectedOption,
  validationMessage,
}) => {
  const dispatch = useDispatch();
  const { riskState } = useSelector((state) => state.riskStateReducer);
  const { dynamicValue, showValidation } = useSelector(
    (state) => state.inputValueReducer
  );
  const { range } = useSelector((state) => state.searchByReducer.dynamicField);

  /* istanbul ignore next */
  useEffect(() => {
    // update the WC validation/helper text for PA and DE
    if (selectedOption === "Workers' Comp code") {
      dispatch(changeSearchBy(updateWCSearchType(riskState)));
    }
  }, [riskState]);

  /* istanbul ignore next */
  useEffect(() => {
    if (dynamicValue) {
      handleValidationMessage();
    }
  }, [range]);

  /* istanbul ignore next */
  useEffect(() => {
    // below are used for changing radio btn selection and resetting input field value and any validation that may have fired on dynamic input
    dispatch(updateDynamicValidation(false));
  }, [activeTabID, selectedOption]);

  const checkFieldValid = () => {
    if (!dynamicValue || dynamicValue.length < range[0]) {
      trackCustomEventError(
        `${selectedOption}`,
        `${validationMessage}`,
        "true"
      );
      return false;
    } else {
      return true;
    }
  };

  const handleValidationMessage = () => {
    trackCustomEvent(
      `${pageTag}`,
      `${inputFieldClickdAnalytics}`,
      `${selectedOption}`,
      `${dynamicValue}`,
      "true"
    );

    dispatch(updateDynamicValidation(!checkFieldValid()));
  };

  return (
    <HukInputField
      required
      nopadding
      nooptionaltext
      className="mb-4"
      gbxunmask={true}
      requiredasterisk
      labelformat="text"
      nohelpertextonerror
      value={dynamicValue}
      maxlength={range[1]}
      label={selectedOption}
      refresh={refreshCount}
      helpertextformat="text"
      helpertext={helperText}
      data-testid="DynamicInputID"
      dataerror={validationMessage}
      showdataerror={showValidation}
      datamissing={validationMessage}
      onBlur={handleValidationMessage}
      hukclass="number-positive-only-no-decimal"
      disablevalidation={activeTabID !== "spectrumTabID"}
      onInput={(e) => dispatch(updateDynamicValue(e.target.value))}
    />
  );
};

export default NumberInput;
