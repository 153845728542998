import { useEffect } from "react";
import { tealiumModule } from "hig-tealium-core";

import { getAppEnv } from "env/env";

/**
 * Base Tealium Setup
 * @returns {null} - null
 */
const BaseTeliumSetup = () => {
  let loaded = false;
  useEffect(() => {
    let udo = {
      site_name: "iconcafe",
    };
    if (!loaded) {
      tealiumModule("cafe", getAppEnv().TEALIUM_ENVIRONMENT, udo);
      loaded = true;
    }
  }, []);

  return null;
};

export default BaseTeliumSetup;
