import React from "react";
import { useSelector } from "react-redux";

import SearchResultsErrorWrapper from "components/SearchPage/SearchResultsErrorWrapper/SearchResultsErrorWrapper";

import WCDesktopResultsTable from "./Desktop/WCCodeDesktopResultsTable";

export const WCCodeSearchResults = ({ activeTabID }) => {
  const { data } = useSelector((state) => state.searchResultsReducer);

  const WCSearchResultsJson = {
    tableCss: {
      compact: true,
      stickyhdr: true,
    },
    tableHeadings: [
      {
        colId: 1,
        heading: "WC Code",
        sortable: true,
        width: "9rem",
      },
      {
        colId: 2,
        heading: "Desc Code",
        sortable: true,
        width: "9rem",
      },
      {
        colId: 3,
        heading: "Description",
        sortable: true,
        width: "50rem",
      },
    ],
    tableRows: data.map((item) => ({
      tableData: [
        {
          colId: 1,
          content: item.classCode,
        },
        {
          colId: 2,
          content: item.descriptorCd,
        },
        {
          colId: 3,
          format: "html",
          content: `<div style='font-weight: 500'>${item.descriptorText}</div>`,
        },
      ],
    })),
  };

  // show appropriate table based on screen size
  return (
    <SearchResultsErrorWrapper activeTabID={activeTabID}>
      <div>
        <WCDesktopResultsTable WCSearchResultsJson={WCSearchResultsJson} />
      </div>
    </SearchResultsErrorWrapper>
  );
};

export default WCCodeSearchResults;
