import { combineReducers } from "redux";

import pageNameReducer from "./pageNameReducer";
import searchByReducer from "./searchByReducer";
import riskStateReducer from "./riskStateReducer";
import inputValueReducer from "./inputValueReducer";
import searchResultsReducer from "./searchResultsReducer";
import businessSearchReducer from "./businessSearchReducer";
import workbinProducerCodesReducer from "./workbinProducerCodesReducer";

const rootReducer = combineReducers({
  pageNameReducer,
  searchByReducer,
  riskStateReducer,
  searchResultsReducer,
  inputValueReducer,
  businessSearchReducer,
  workbinProducerCodesReducer,
});

export default rootReducer;
