import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { HukIcon } from "huk-react-bindings";

import "./ErrorMessage.scss";

const ErrorMessage = ({
  error,
  message = null,
  icon,
  showLink = false,
  linkText = null,
  linkAddress = null,
  utilizeNavLink = false,
  setHasQueryParams = () => {},
}) => {
  const { pageName } = useSelector((state) => state.pageNameReducer);

  /**
   * NavLink is better for redirecting inside the app
   * Where as anchor tag is better for external links
   * EBC Logout requires user re-directed outside of our app to EBC login page
   */
  const LinkComponent = utilizeNavLink ? NavLink : "a";
  const handleLinkClick = () => {
    // re-render the home page if the "Return to Search" link in the error message is clicked
    if (linkAddress === "/") {
      setHasQueryParams(false);
    }
  };
  const linkProps = utilizeNavLink
    ? { to: linkAddress, onClick: handleLinkClick }
    : { href: linkAddress };

  return (
    <div
      data-testid="ErrorMessageID"
      className={
        "error_message_container d-flex align-items-center justify-content-center text-center"
      }
    >
      {/* logic used for centering message on error page */}
      <div className={`${pageName !== "searchPage" && "error_message_page"}`}>
        <HukIcon iconsize="3x" icon={icon} className="info-icon" />
        <h1>{error}</h1>
        {/* this check for null will only be applicable as a placeholder fix for CIAM token not refreshing and will be removed when permanent fix goes for Sept release */}
        {message != null &&
          message.split(". ").map((line, index) => (
            <div key={index} className="message">
              {line}.
            </div>
          ))}
        {showLink && (
          <div className="mt-2">
            <LinkComponent
              {...linkProps}
              className="return_to_Search_link text-decoration-none"
            >
              {linkText}
            </LinkComponent>
          </div>
        )}
      </div>
    </div>
  );
};

export default ErrorMessage;
