import React from "react";
import { HukPopover } from "huk-react-bindings";

import { getWCCodes, setClassCodeDescriptionText } from "../shared/shared";
import AlternativeMarketPlan from "../AlternativeMarketPlan/AlternativeMarketPlan";

import "./ClassDetailsExternal.scss";

const ClassDetailsExternal = ({ searchData, reportData }) => {
  const { exposureBaseName, marketGroupName, naicscode } = searchData || {};

  const wcCodes = getWCCodes(reportData);

  return (
    <div className="class_details_container my-5">
      <div className="class_details_title mb-3">
        <h2>Class Details</h2>
      </div>
      <div className="container-fluid d-flex flex-row rounded-3 class_detail_card">
        <div className="row mx-0">
          <div className="col-md-7 pt-4 ps-4">
            <div className="row mb-3">
              <div className="col-sm-4 class_detail_color">Exposure Basis</div>
              <div className="col-sm fw-medium">{exposureBaseName}</div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-4 class_detail_color">Common WC Codes</div>
              <div className="col-sm fw-medium class_detail_color">
                {Object.keys(wcCodes)?.length > 0
                  ? Object.keys(wcCodes).map((item, index) => (
                      <div key={index} className="pop_over">
                        <HukPopover
                          buttontext={item}
                          type="tooltip-link"
                          icon="attention-alt"
                          popovertitle="Description"
                          data-testid="HukPopoverId"
                        >
                          {setClassCodeDescriptionText(item, wcCodes)}
                        </HukPopover>
                        {index !== Object.keys(wcCodes).length - 1 ? (
                          <p>, </p>
                        ) : null}
                      </div>
                    ))
                  : "--"}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-4 class_detail_color">Market Group</div>
              <div className="col-sm fw-medium">{marketGroupName}</div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-4 class_detail_color">SIC</div>
              <div className="col-sm fw-medium">
                {searchData?.siccode + " - " + searchData?.sicdescription}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-4 class_detail_color">NAICS</div>
              <div className="col-sm fw-medium ">{naicscode}</div>
            </div>
          </div>
          <div className="col-md-5 px-0">
            <AlternativeMarketPlan searchData={searchData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClassDetailsExternal;
