import React, { useEffect } from "react";
import { HukInputField } from "huk-react-bindings";
import { useDispatch, useSelector } from "react-redux";

import { inputFieldClickdAnalytics, pageTag } from "shared/messages";
import {
  trackCustomEventError,
  trackCustomEvent,
} from "tealium/trackCustomEvent";
import {
  updateDynamicValue,
  updateDynamicValidation,
} from "redux/actions/inputValueActions";

const TextInput = ({
  range,
  helperText,
  activeTabID,
  refreshCount,
  selectedOption,
  validationMessage,
}) => {
  const dispatch = useDispatch();
  const { dynamicValue, showValidation } = useSelector(
    (state) => state.inputValueReducer
  );

  useEffect(() => {
    dispatch(updateDynamicValidation(false));
  }, [activeTabID]);

  const checkFieldValid = () => {
    const dynamicInputStringValue = dynamicValue.trim().toString();
    if (!dynamicInputStringValue || dynamicInputStringValue.length < range[0]) {
      dispatch(updateDynamicValidation(true));
      trackCustomEventError(
        `${selectedOption}`,
        `${validationMessage}`,
        "true"
      );
    } else {
      dispatch(updateDynamicValidation(false));
      trackCustomEvent(
        `${pageTag}`,
        `${inputFieldClickdAnalytics}`,
        `Description`,
        `${dynamicValue}`,
        "true"
      );
    }
  };

  return (
    <HukInputField
      required
      nopadding
      nooptionaltext
      className="mb-4"
      gbxunmask={true}
      requiredasterisk
      labelformat="text"
      nohelpertextonerror
      maxlength={range[1]}
      value={dynamicValue}
      label={selectedOption}
      refresh={refreshCount}
      helpertextformat="text"
      helpertext={helperText}
      onBlur={checkFieldValid}
      data-testid="TextInputID"
      dataerror={validationMessage}
      showdataerror={showValidation}
      datamissing={validationMessage}
      disablevalidation={activeTabID !== "spectrumTabID"}
      onInput={(e) => dispatch(updateDynamicValue(e.target.value))}
    />
  );
};

export default TextInput;
