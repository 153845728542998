import React from "react";
import { useSelector } from "react-redux";

import { allTALDAppetiteCode } from "shared/helper";
import SearchResultsErrorWrapper from "components/SearchPage/SearchResultsErrorWrapper/SearchResultsErrorWrapper";

import SpectrumMobileResultsTable from "./Mobile/SpectrumMobileResultsTable";
import SpectrumDesktopResultsTable from "./Desktop/SpectrumDesktopResultsTable";

export const SpectrumSearchResults = ({ activeTabID }) => {
  const { data } = useSelector((state) => state.searchResultsReducer);
  /**
   * Get the appropriate icon and color/styling for the results table lob appetite columns
   * @param {string} appetiteCd Appetite code from the response data for the specific lob
   * @return {string} huk icon in html format that is needed to set the icon and color for JSON object
   */
  const getHukTableIcon = (appetiteCd) => {
    return `<huk-icon icon='${allTALDAppetiteCode(
      appetiteCd,
      "icon"
    )}' style='color: ${allTALDAppetiteCode(appetiteCd, "color")}'>`;
  };

  const searchResultsJSON = {
    tableCss: {
      compact: true,
      stickyhdr: true,
    },
    tableHeadings: [
      {
        colId: "1",
        heading: "BOP Code",
        sortable: "true",
        dataField: "accept",
      },
      {
        colId: "2",
        heading: "ISO GL",
        sortable: "true",
        width: "5.5rem",
      },
      {
        colId: "3",
        heading: "SIC",
        sortable: "true",
      },
      {
        colId: "4",
        heading: "NAICS",
        sortable: "true",
        dataField: "policy",
        dataType: "number",
      },
      {
        colId: "5",
        heading: "Description",
        sortable: "true",
        dataField: "owner",
        width: "30rem",
      },
      {
        colId: "6",
        heading: "BOP",
      },
      {
        colId: "7",
        heading: "WC",
      },
      {
        colId: "8",
        heading: "Auto",
      },
      {
        colId: "9",
        heading: "Mgt Liab",
      },
      {
        colId: "10",
        heading: "Prof Liab",
      },
      {
        colId: "11",
        heading: "",
        sortable: "false",
        dataField: "submit",
        hukComponent: "button",
      },
      {
        colId: "11",
      },
    ],
    tableRows: data.map((item) => {
      return {
        tableData: [
          {
            colId: "1",
            content:
              item.businessClassCdTypeName === "SPCTRM" ? item.classCode : "--",
          },
          {
            colId: "2",
            content:
              item.businessClassCdTypeName === "ISO_CGL"
                ? item.classCode
                : "--",
          },
          {
            colId: "3",
            content: item.siccode,
          },
          {
            colId: "4",
            content: item.naicscode,
          },
          {
            colId: "5",
            format: "html",
            content: `<div style='font-weight: 500'>${item.descriptorText}</div>`,
          },
          {
            colId: "6",
            format: "html",
            content:
              item.specTALDAppetiteCd === "--" ||
              item.specTALDAppetiteCd === null
                ? "--"
                : getHukTableIcon(item.specTALDAppetiteCd),
          },
          {
            colId: "7",
            format: "html",
            content:
              item.wctaldappetiteCd === "--" || item.wctaldappetiteCd === null
                ? "--"
                : getHukTableIcon(item.wctaldappetiteCd),
          },
          {
            colId: "8",
            format: "html",
            content:
              item.cataldappetiteCd === "--" || item.cataldappetiteCd === null
                ? "--"
                : getHukTableIcon(item.cataldappetiteCd),
          },
          {
            colId: "9",
            format: "html",
            content:
              item.mltaldappetiteCode === "--" ||
              item.mltaldappetiteCode === null
                ? "--"
                : getHukTableIcon(item.mltaldappetiteCode),
          },
          {
            colId: "10",
            format: "html",
            content:
              item.scpltaldaappetiteCode === "--" ||
              item.scpltaldaappetiteCode === null
                ? "--"
                : getHukTableIcon(item.scpltaldaappetiteCode),
          },
          {
            colId: "11",
            value: "Details",
            hukclass: "btn-secondary btn-xs",
            align: "right",
            buttonIcon: "icon icon-caret-right",
            onclick: "showDetailsModal",
          },
          {
            colId: "12",
            format: "html",
            content: `<div style='size: 0px; display: none'>${item.descriptorCd}</div>`,
          },
        ],
      };
    }),
  };

  // show appropriate table based on screen size
  return (
    <SearchResultsErrorWrapper activeTabID={activeTabID}>
      <div>
        {/* show desktop table when screen is >992px */}
        <div className="d-none d-lg-block">
          <SpectrumDesktopResultsTable searchResultsJSON={searchResultsJSON} />
        </div>
        {/* show mobile table when screen is <992px */}
        <div className="d-block d-lg-none">
          <SpectrumMobileResultsTable searchResultsJSON={searchResultsJSON} />
        </div>
      </div>
    </SearchResultsErrorWrapper>
  );
};

export default SpectrumSearchResults;
