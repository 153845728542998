import React from "react";
import { HukRadio } from "huk-react-bindings";
import { useSelector, useDispatch } from "react-redux";

import { options } from "shared/globalData";
import { changeSearchBy } from "redux/actions/searchByActions";
import { searchReset } from "redux/actions/searchResultsActions";

import { updateWCSearchType } from "./helper";
import SpectrumSearch from "./SpectrumSearch/SpectrumSearch";
import BusinessSearch from "./BusinessSearch/BusinessSearch";

import "./SpectrumClassSearch.scss";

const SpectrumClassSearch = ({ activeTabID }) => {
  const dispatch = useDispatch();
  const { riskState } = useSelector((state) => state.riskStateReducer);
  const { selectedOption } = useSelector((state) => state.searchByReducer);

  /**
   * Updates redux store with the selected radio button value
   * @param {object} event - Radio button custom event object
   */
  const handleOptionChange = (event) => {
    dispatch(searchReset());

    // update the WC validation/helper text for PA and DE
    if (event.target.checkedOption.name === "Workers' Comp code") {
      dispatch(changeSearchBy(updateWCSearchType(riskState)));
      return;
    }

    dispatch(changeSearchBy(event.target.checkedOption));
  };

  return (
    <div
      data-testid="spectrumClassSearchID"
      className="spectrum_class_search_container container mt-3"
    >
      <div className="row">
        <div className="col-md-5 col-lg-4 col-xl-3">
          <div className="d-flex">
            <div>
              <HukRadio
                required
                options={options}
                grouplabel="Search By"
                onHukInput={handleOptionChange}
                data-testid="SearchByRadioID"
                data-dl={`{"da_track": "true"}`}
              />
            </div>

            {/* vertical dividing line between radio buttons and inputs on desktop */}
            <div className="d-none d-md-block spectrum_vertical_divider"></div>
          </div>
        </div>

        {/* horizontal dividing line between radio buttons and inputs on desktop */}
        <div className="d-block d-md-none my-3 dividing_line_bottom_mobile"></div>

        {selectedOption !== "Business search" ? (
          <SpectrumSearch activeTabID={activeTabID} />
        ) : (
          <BusinessSearch activeTabID={activeTabID} />
        )}
      </div>
    </div>
  );
};

export default SpectrumClassSearch;
