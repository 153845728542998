const INITIAL_STATE = {
  data: [],
  loading: null,
  error: null,
};

const searchResultsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SEARCH_START":
      return { data: [], loading: true, error: null };
    case "SEARCH_SUCCESS":
      return { data: action.payload, loading: false, error: null };
    case "SEARCH_FAILURE":
      return { data: [], loading: false, error: action.payload };
    case "SEARCH_RESET":
      return { data: [], loading: null, error: null };
    default:
      return state;
  }
};

export default searchResultsReducer;
