import { useEffect, useState, useRef } from "react";
import { HukTabs, HukTab } from "huk-react-bindings";
import { useDispatch, useSelector } from "react-redux";
import { trackPageView, updateUDOObject } from "hig-tealium-core";

import { axiosPostInstance } from "shared/axiosInstance";
import { getDefaultProducerCodeURL } from "shared/helper";
import {
  somethingWentWrong,
  somethingWentWrongDescription,
  returnToSearch,
} from "shared/messages";
import { updatePageName } from "redux/actions/pageNameAction";
import { searchReset } from "redux/actions/searchResultsActions";
import RiskState from "components/SearchPage/RiskState/RiskState";
import { updateWorkbinCodes } from "redux/actions/workbinProducerCodesActions";
import { useLocation } from "react-router-dom";

import WCCodeSearch from "./WCCodeSearchTab/WCCodeSearch";
import AppetiteGuideSearch from "./AppetiteGuideTab/AppetiteGuideSearch";
import SpectrumClassSearch from "./SpectrumClassSearchTab/SpectrumClassSearch";
import WCCodeSearchResults from "./WCCodeSearchTab/SearchResults/WCCodeSearchResults";
import SpectrumSearchResults from "./SpectrumClassSearchTab/SearchResults/SpectrumSearchResults";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import "./SearchPage.scss";

const SearchPage = () => {
  const dispatch = useDispatch();
  const hukTabsRef = useRef(null);
  const searchPageRef = useRef(false);
  const location = useLocation();
  const userID = sessionStorage.getItem("ebcUID");
  const [activeTabID, setActiveTabID] = useState("spectrumTabID");
  const { workbinProducerCodes } = useSelector(
    (state) => state.workbinProducerCodesReducer
  );
  const [hasQueryParams, setHasQueryParams] = useState(location?.search);
  const userType = sessionStorage.getItem("userType");

  useEffect(() => {
    /**
     * Only call workbin service on initial page load AND if user is external
     * This prevents call from being made again when coming back to search page from details page
     */
    if (userType === "external" && workbinProducerCodes?.length === 0) {
      axiosPostInstance()
        .post(getDefaultProducerCodeURL, { userId: userID })
        .then((res) => {
          const workbinCodes = res?.data?.ContractGroupList?.map(
            (item) => item.ContractNumber
          );
          dispatch(updateWorkbinCodes(workbinCodes));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  /* istanbul ignore next */
  useEffect(() => {
    if (!hasQueryParams) {
      dispatch(updatePageName("searchPage"));

      const hukTabs = hukTabsRef.current;

      const handleTabClick = (e) => {
        /*
          Only dispatch searchReset when changing tabs
          This allows for the results table to return to the search page when coming back from Class Details Page via the link
        */
        dispatch(searchReset());
        setActiveTabID(e.detail.tab.tabId);
      };

      hukTabs.addEventListener("hukTabsClickEvent", handleTabClick);

      return () => {
        hukTabs.removeEventListener("hukTabsClickEvent", handleTabClick);
      };
    }
  }, []);

  /* istanbul ignore next */
  useEffect(() => {
    // on load of page set the UDO object for the UID and the user type
    updateUDOObject({
      ebcid:
        sessionStorage.getItem("ebcUID") ??
        sessionStorage.getItem("internalUID"),
      user_relationship: sessionStorage.getItem("userType"),
    });

    if (sessionStorage.getItem("classDetailsPage") === "true") {
      updateUDOObject({
        previous_page_name: "Class Details Page",
        class_id: "",
        state: "",
        ind_desc_ol_comm: "",
      });
      sessionStorage.removeItem("classDetailsPage");
      searchPageRef.current = true;
    }

    if (
      (!sessionStorage.getItem("classDetailsPage") && !searchPageRef.current) ||
      searchPageRef.current
    ) {
      trackPageView("Search Page");
    }
  }, []);

  useEffect(() => {
    if (location?.search) {
      setHasQueryParams(true);
    }
  }, [location]);

  /**
   * Sets the the active HUK tab to be shown on screen after click of a link
   * @param {string} newActiveTabID - New active HUK tab ID
   * @return {void}
   */
  /* istanbul ignore next */
  const handleActiveTab = (newActiveTabID) => {
    setActiveTabID(newActiveTabID);
  };

  return (
    <>
      {hasQueryParams ? (
        <div>
          <ErrorMessage
            linkAddress="/"
            showLink={true}
            utilizeNavLink={true}
            linkText={returnToSearch}
            icon="icon icon-alert-warning"
            error={somethingWentWrong}
            message={somethingWentWrongDescription}
            setHasQueryParams={setHasQueryParams}
          />
        </div>
      ) : (
        <div
          data-testid="search_page_ID"
          className="search_page_container container mt-3"
        >
          <div>
            <div className="container">
              <div className="row mb-3">
                <div className="col-md-7 col-lg-4 col-xl-3 me-3 mb-3 mb-md-0">
                  <RiskState activeTabID={activeTabID} />
                </div>
              </div>
            </div>
            <div className="tabs">
              <HukTabs
                refresh="0"
                theme="soft"
                ref={hukTabsRef}
                tabwidth={"250px"}
                active-tab-id={activeTabID}
                data-dl={`{"da_track": "true"}`}
              >
                <HukTab
                  no-padding
                  tab-id="spectrumTabID"
                  tab-title="Spectrum Class Search"
                >
                  <div
                    slot="tab-pane-content"
                    className="spectrum_class_search_tab"
                  >
                    <div className="spectrum_class_search">
                      <SpectrumClassSearch activeTabID={activeTabID} />
                    </div>
                    <div className="dividing_line_bottom"></div>
                  </div>
                </HukTab>

                <HukTab tab-id="wcTabID" tab-title="WC Code Search">
                  <div className="wc_search_tab" slot="tab-pane-content">
                    <WCCodeSearch activeTabID={activeTabID} />
                    <div className="dividing_line_bottom"></div>
                  </div>
                </HukTab>

                <HukTab tab-id="appetiteTabID" tab-title="Appetite Guide">
                  <div className="appetite_guide_tab" slot="tab-pane-content">
                    <AppetiteGuideSearch
                      activeTabID={activeTabID}
                      handleActiveTab={handleActiveTab}
                    />

                    <div className="dividing_line_bottom"></div>
                  </div>
                </HukTab>
              </HukTabs>
            </div>
            {activeTabID === "spectrumTabID" && (
              <SpectrumSearchResults activeTabID="spectrumTabID" />
            )}
            {activeTabID === "wcTabID" && (
              <WCCodeSearchResults activeTabID="wcTabID" />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default SearchPage;
