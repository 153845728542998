export const updateDynamicValue = (payload) => {
  return {
    type: "UPDATE_DYNAMIC_VALUE",
    payload,
  };
};

export const updateProducerCode = (payload) => {
  return {
    type: "UPDATE_PRODUCER_CODE",
    payload,
  };
};

export const updateDynamicValidation = (payload) => {
  return {
    type: "UPDATE_DYNAMIC_VALIDATION",
    payload,
  };
};

export const updateCPID = (payload) => {
  return {
    type: "UPDATE_CPID",
    payload,
  };
};
