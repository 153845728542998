// Initial state
const initialState = {
  riskState: localStorage.getItem("riskState") ?? "",
  showRiskStateValidation: false,
};

// Reducer function
const riskStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_RISK_STATE":
      return {
        riskState: action.payload,
      };
    case "UPDATE_RISK_STATE_VALIDATION":
      return {
        ...state,
        showRiskStateValidation: action.payload,
      };
    default:
      return state;
  }
};

export default riskStateReducer;
