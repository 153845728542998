import React from "react";
import { useSelector } from "react-redux";

import logo from "assets/images/logo.png";

import "./Header.scss";

const Header = () => {
  const { pageName } = useSelector((state) => state.pageNameReducer);

  return (
    <>
      {/* container-fluid class is used to make the borders span width of the window */}
      <div className="header_container" data-testid="HeaderID">
        {/* Mobile styling */}
        <header
          className="py-2 d-block d-sm-none mobile_blue_header_border"
          data-testid="HeaderMobileID"
        >
          <div className="d-flex align-items-center container-fluid">
            <img src={logo} alt="The Hartford" className="header_logo" />
            {pageName === "searchPage" && (
              <div className="title_mobile_SearchPage">
                Small Commercial Sales & Appetite Guides
              </div>
            )}
          </div>
        </header>

        {/* Desktop styling */}
        {/* <p>This is placeholder text</p> */}
        <header className="d-none d-sm-block">
          <div className="primary_header_desktop">
            {pageName === "searchPage" && (
              <div className="container d-flex align-items-center justify-content-between title_and_agency_row_SearchPage">
                <div className="title_SearchPage">
                  Small Commercial Sales & Appetite Guides
                </div>
              </div>
            )}
          </div>
          <div className="container">
            <img
              src={logo}
              alt="The Hartford"
              className="header_logo d-none d-sm-block"
            />
          </div>
          <div className="secondary_header_desktop d-none d-sm-block"></div>
        </header>
      </div>
    </>
  );
};

export default Header;
