// Initial state
const initialState = {
  pageName: "searchPage",
};

const pageNameReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_PAGE_NAME":
      return {
        pageName: action.payload,
      };
    default:
      return state;
  }
};

export default pageNameReducer;
