import { LOCAL } from "./local";
import { INT } from "./int";
import { INT2 } from "./int2";
import { QA } from "./qa";
import { QA2 } from "./qa2";
import { PTE } from "./pte";
import { PROD } from "./prod";
import { GLOBAL } from "./global";

export const getAppEnv = () => {
  const hostName = document.location.hostname;
  let appEnv = "";

  if (hostName.includes("localhost")) {
    appEnv = LOCAL;
  } else if (hostName.includes("int-")) {
    appEnv = INT;
  } else if (hostName.includes("int2-")) {
    appEnv = INT2;
  } else if (hostName.includes("qa-")) {
    appEnv = QA;
  } else if (hostName.includes("qa2-")) {
    appEnv = QA2;
  } else if (hostName.includes("pte-")) {
    appEnv = PTE;
  } else {
    appEnv = PROD;
  }

  return appEnv;
};

export const getGlobalEnv = () => GLOBAL;
