import React from "react";
import ReactDOM from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";

import store from "redux/store";
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";

import App from "./App";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <Header />
      <App />
      <Footer />
    </ReduxProvider>
  </React.StrictMode>
);
