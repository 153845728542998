import { requiredFieldErrorMessage } from "./messages";

export const stateList = [
  {
    name: "Alabama",
    value: "AL",
  },
  {
    name: "Arizona",
    value: "AZ",
  },
  {
    name: "Arkansas",
    value: "AR",
  },
  {
    name: "California",
    value: "CA",
  },
  {
    name: "Colorado",
    value: "CO",
  },
  {
    name: "Connecticut",
    value: "CT",
  },
  {
    name: "Delaware",
    value: "DE",
  },
  {
    name: "District of Columbia",
    value: "DC",
  },
  {
    name: "Florida",
    value: "FL",
  },
  {
    name: "Georgia",
    value: "GA",
  },
  {
    name: "Idaho",
    value: "ID",
  },
  {
    name: "Illinois",
    value: "IL",
  },
  {
    name: "Indiana",
    value: "IN",
  },
  {
    name: "Iowa",
    value: "IA",
  },
  {
    name: "Kansas",
    value: "KS",
  },
  {
    name: "Kentucky",
    value: "KY",
  },
  {
    name: "Louisiana",
    value: "LA",
  },
  {
    name: "Maine",
    value: "ME",
  },
  {
    name: "Maryland",
    value: "MD",
  },
  {
    name: "Massachusetts",
    value: "MA",
  },
  {
    name: "Michigan",
    value: "MI",
  },
  {
    name: "Minnesota",
    value: "MN",
  },
  {
    name: "Mississippi",
    value: "MS",
  },
  {
    name: "Missouri",
    value: "MO",
  },
  {
    name: "Montana",
    value: "MT",
  },
  {
    name: "Nebraska",
    value: "NE",
  },
  {
    name: "Nevada",
    value: "NV",
  },
  {
    name: "New Hampshire",
    value: "NH",
  },
  {
    name: "New Jersey",
    value: "NJ",
  },
  {
    name: "New Mexico",
    value: "NM",
  },
  {
    name: "New York",
    value: "NY",
  },
  {
    name: "North Carolina",
    value: "NC",
  },
  {
    name: "North Dakota",
    value: "ND",
  },
  {
    name: "Ohio",
    value: "OH",
  },
  {
    name: "Oklahoma",
    value: "OK",
  },
  {
    name: "Oregon",
    value: "OR",
  },
  {
    name: "Pennsylvania",
    value: "PA",
  },
  {
    name: "Rhode Island",
    value: "RI",
  },
  {
    name: "South Carolina",
    value: "SC",
  },
  {
    name: "South Dakota",
    value: "SD",
  },
  {
    name: "Tennessee",
    value: "TN",
  },
  {
    name: "Texas",
    value: "TX",
  },
  {
    name: "Utah",
    value: "UT",
  },
  {
    name: "Vermont",
    value: "VT",
  },
  {
    name: "Virginia",
    value: "VA",
  },
  {
    name: "Washington",
    value: "WA",
  },
  {
    name: "West Virginia",
    value: "WV",
  },
  {
    name: "Wisconsin",
    value: "WI",
  },
  {
    name: "Wyoming",
    value: "WY",
  },
];

export const options = [
  {
    name: "Description",
    value: "Description",
    searchType: "SUPER_DESCRIPTION_SEARCH_CONTAINS",
    checked: true,
    dynamicField: {
      range: [3, 60],
      validationMessage: "Enter at least 3 characters",
      helperText: "Enter at least 3 characters",
    },
  },
  // Business Search is being removed for September release.  Remove comment to re-enable
  // {
  //   name: "Business search",
  //   value: "Business search",
  //   searchType: "CLASS_CODE_SEARCH_EQUAL",
  //   dynamicField: {
  //     validationMessage: `${requiredFieldErrorMessage}`,
  //   },
  // },
  {
    name: "NAICS code",
    value: "NAICS code",
    searchType: "NAICS_CODE_SEARCH_EQUAL",
    checked: false,
    dynamicField: {
      range: [2, 6],
      validationMessage: "Enter at least 2 numbers",
      helperText: "Enter between 2-6 numbers ",
    },
  },
  {
    name: "SIC code",
    value: "SIC code",
    searchType: "SIC_CODE_SEARCH_EQUAL",
    checked: false,
    dynamicField: {
      range: [2, 4],
      validationMessage: "Enter at least 2 numbers",
      helperText: "Enter between 2-4 numbers",
    },
  },
  {
    name: "Workers' Comp code",
    value: "Workers' Comp code",
    searchType: "WC_CODE_SEARCH_EQUAL",
    checked: false,
    dynamicField: {
      range: [4, 4],
      validationMessage: "Enter 4 digits",
      helperText: "Enter 4-digit NCCI code",
    },
  },
  {
    name: "ISO GL code",
    value: "ISO GL code",
    searchType: "CLASS_CODE_SEARCH_EQUAL",
    checked: false,
    dynamicField: {
      range: [5, 5],
      validationMessage: "Enter 5 numbers",
      helperText: "Enter 5 numbers",
    },
  },
  {
    name: "Spectrum code",
    value: "Spectrum class code",
    searchType: "CLASS_CODE_SEARCH_EQUAL",
    checked: false,
    dynamicField: {
      range: [5, 5],
      validationMessage: "Enter 5 numbers",
      helperText: "Enter 5 numbers",
    },
  },
];

export const businessTypes = [
  {
    name: "All Business Types",
    value: "AppGuideAllIndustries",
  },
  {
    name: "Associations",
    value: "Associations",
  },
  {
    name: "Business Personal Services - Other",
    value: "BusinessPersonalServi",
  },
  {
    name: "Business / Professional / Personal Services",
    value: "BusinessProfessionalP",
  },
  {
    name: "Contractors",
    value: "Contractors",
  },
  {
    name: "Financial Services",
    value: "FinancialServices",
  },
  {
    name: "Manufacturers",
    value: "Manufacturers",
  },
  {
    name: "Outpatient Healthcare",
    value: "OutpatientHealthcare",
  },
  {
    name: "Printers",
    value: "Printers",
  },
  {
    name: "Retailers",
    value: "Retailers",
  },
  {
    name: "Technology and Life Sciences",
    value: "TechnologyandLifeScie",
  },
  {
    name: "Vets, Architects, Engineers",
    value: "Vets,Architects,Engin",
  },
  {
    name: "Wholesalers",
    value: "Wholesalers",
  },
  {
    name: "All Other Business Types",
    value: "AllOtherBusinessTypes",
  },
];
