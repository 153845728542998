import { INT } from "./int";

export const LOCAL = {
  EBC_DOMAIN: INT.EBC_DOMAIN,
  ICON_DOMAIN: INT.ICON_DOMAIN,
  INTERNAL_AUTH: {
    CLIENT_ID: "12ebfdc3-fe3b-4913-ad09-2e1ce39fd0ed",
    AUTHORITY:
      "https://login.microsoftonline.com/a311fc62-83f4-45f0-9502-1bb2247d4c8d/",
  },
  EXTERNAL_AUTH: {
    CLIENT_ID: "8761f5343fbdfa6688d98a93ce1f0f88",
    AUTHORITY:
      "https://int-apis-hig.thehartford.com/cafe-oauth-ciam-session/oauth2",
    KNOWAUTHORITIES: ["https://int-apis-hig.thehartford.com"],
    REDIRECT_URI: "http://localhost:3000",
    SCOPES: ["cafe.app.external.user"],
  },
  BASIC_API_URL: "https://int-APIs-hig.thehartford.com/cafe/v1",
  IBM_CLIENT_ID: "8761f5343fbdfa6688d98a93ce1f0f88",
  TEALIUM_ENVIRONMENT: "dev",
};
