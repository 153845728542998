import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { MsalProvider } from "@azure/msal-react";
import BaseTeliumSetup from "tealium/BaseTeliumSetup";
import { PublicClientApplication } from "@azure/msal-browser";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { getAppEnv } from "env/env";
import InternalAuthProvider from "auth/internal/InternalAuthProvider";
import ExternalAuthProvider from "auth/external/ExternalAuthProvider";
import { internalConfiguration } from "auth/internal/internalAuthConfig";
import { externalConfiguration } from "auth/external/externalAuthConfig";

import SearchPage from "components/SearchPage/SearchPage";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import ClassDetailPage from "components/ClassDetailPage/ClassDetailPage";
import {
  somethingWentWrong,
  somethingWentWrongDescription,
  returnToSearch,
} from "shared/messages";
import "./App.scss";

const ipca = new PublicClientApplication(internalConfiguration);
const epca = new PublicClientApplication(externalConfiguration);

const router = createBrowserRouter([
  {
    path: "/",
    element: <SearchPage />,
  },
  {
    path: "/classDataSearch",
    element: <ClassDetailPage />,
  },
  {
    path: "*",
    element: (
      <ErrorMessage
        linkAddress="/"
        showLink={true}
        useNavLink={true}
        linkText={returnToSearch}
        icon="icon icon-alert-warning"
        error={somethingWentWrong}
        message={somethingWentWrongDescription}
      />
    ),
  },
]);

const App = () => {
  const [cookies, setCookies] = useCookies(["CIAMSession", "hartfordEmployee"]);
  const url = window?.location?.href;
  // add  || url.includes("localhost") to isIntranetUrl below to force local development to internal user
  const isIntranetUrl = url.includes("intranet.appetite.thehartford.com");
  BaseTeliumSetup();

  // used for mocking external user with CIAMSession in local
  useEffect(() => {
    sessionStorage.clear();
    let userType = "external";
    if (isIntranetUrl) {
      userType = "internal";
    }
    sessionStorage.setItem("userType", userType);
    if (window.location.origin.includes("localhost")) {
      // CIAMSession value should be obtained from EBC login and pasted here to authenticate as external user
      // comment out entire setCookies call to authenticate as internal user
      // setCookies(
      //   "CIAMSession",
      //   "6FZhu7xklcOuU2IZ4Ueo-qFblzQ.*AAJTSQACMDIAAlNLABxwUm1ZWFNib3lmN1ZwMzFLbkVWS05qUDR4K2c9AAR0eXBlAANDVFMAAlMxAAIwMQ..*"
      // );
    }
  }, []);

  const AuthProviderWrapper = ({ children }) => {
    if (cookies.CIAMSession && !isIntranetUrl) {
      return (
        <MsalProvider instance={epca}>
          <ExternalAuthProvider>{children}</ExternalAuthProvider>
        </MsalProvider>
      );
    } else if (!isIntranetUrl) {
      /**
         If there was a CIAM session and it has been removed that means EBC session timed out
         advise user to go back and login to EBC to get a new CIAM session to authenticate CAFE
       */
      return (
        <ErrorMessage
          showLink={true}
          utilizeNavLink={false}
          icon="icon icon-info"
          linkText="Return to login"
          error="Your session has expired"
          linkAddress={getAppEnv().EBC_DOMAIN}
        />
      );
    }

    if (isIntranetUrl) {
      return (
        <MsalProvider instance={ipca}>
          <InternalAuthProvider>{children}</InternalAuthProvider>
        </MsalProvider>
      );
    }
  };

  return (
    <div
      data-testid="cafe-app"
      className="cafe-app d-flex flex-column justify-content-center"
    >
      <AuthProviderWrapper>
        <RouterProvider router={router} />
      </AuthProviderWrapper>
    </div>
  );
};

export default App;
