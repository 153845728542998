import { jwtDecode } from "jwt-decode";
import { useEffect, useRef } from "react";
import {
  InteractionType,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import {
  useMsal,
  useMsalAuthentication,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import { getAppEnv } from "env/env";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";

import { loginRequest, REFRESH_THRESHOLD } from "./internalAuthConfig";

const setRefreshTime = () => {
  const accessToken = sessionStorage.getItem("accessToken");

  if (accessToken) {
    const decodeToken = jwtDecode(accessToken);
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    const expTime = Math.floor(decodeToken.exp);
    const timeUntilExpiry = expTime - currentTime - REFRESH_THRESHOLD;

    return timeUntilExpiry;
  }

  return null;
};

const InternalAuthProvider = ({ children }) => {
  const interval = useRef(null);
  const { instance, accounts } = useMsal();

  const refreshTime = setRefreshTime();

  if (refreshTime !== null && refreshTime <= 0) {
    sessionStorage.clear();
  }

  const { login, result, error } = useMsalAuthentication(
    InteractionType.Silent,
    loginRequest
  );

  // if (error) {
  //   console.log(error);
  // }

  if (result) {
    sessionStorage.setItem("accessToken", result.accessToken);
    sessionStorage.setItem("internalUID", accounts[0]?.username);
  }

  useEffect(() => {
    if (error instanceof InteractionRequiredAuthError) {
      login(InteractionType.Popup, loginRequest).catch((e) => {
        window.location.replace(getAppEnv().EBC_DOMAIN);
      });
    }
  }, [error]);

  const acquireTokenWithRefreshToken = async () => {
    try {
      if (accounts.length > 0 && instance) {
        const response = await instance.acquireTokenSilent({
          account: accounts[0],
          ...loginRequest,
        });

        sessionStorage.setItem("accessToken", response.accessToken);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    setInterval.current = setInterval(() => {
      const refreshTime = setRefreshTime();

      if (refreshTime !== null && refreshTime <= 0) {
        acquireTokenWithRefreshToken();
      }
    }, 60000);

    return () => clearInterval(interval.current);
  }, []);

  return (
    <>
      <UnauthenticatedTemplate data-testid="unauthenticated">
        <LoadingIndicator text="Loading..." fullScreen />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate data-testid="authenticated">
        {children}
      </AuthenticatedTemplate>
    </>
  );
};

export default InternalAuthProvider;
